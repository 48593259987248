<div class="forgot-password" [class.inDialog]="inDialog()">
  <form (ngSubmit)="onResetPasswordSubmit()">
    <a class="logo" [routerLink]="routes.Root">
      <picture>
        <img src="assets/logo-g.webp" class="logo" [height]="64" />
      </picture>
    </a>

    <h2>Forgot password?</h2>
    <div class="existing-account">
      <p>Don't have an account?</p>
      &nbsp;
      <a mat-button color="primary" type="button" [routerLink]="signUpRoute()">Sign up</a>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input matInput type="email" name="email" [formControl]="emailFormControl" />
      <mat-error>Please enter a valid email address.</mat-error>
    </mat-form-field>

    <div class="buttons-container">
      <button mat-flat-button type="submit" color="primary" [disabled]="emailFormControl.invalid || loading()">
        @if (loading()) {
          <mat-spinner [diameter]="36"></mat-spinner>
        } @else {
          Reset password
        }
      </button>

      <p class="or-container">
        <span class="or-line"></span><span class="or-text">or</span><span class="or-line"></span>
      </p>

      <div class="social-buttons">
        <gth-google-button (socialClick)="onGoogleLoginBtnClick()"></gth-google-button>
      </div>
    </div>
  </form>
</div>
